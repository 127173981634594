<template>
  <v-toolbar flat max-height="56">
    <v-btn :disabled="disabled" icon @click.stop="$emit('close')">
      <v-icon>
        {{ svg.mdiClose }}
      </v-icon>
    </v-btn>

    <v-toolbar-title class="pl-0">
      <slot />
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
import { mdiArrowLeft, mdiClose } from '@mdi/js'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    svg: {
      mdiArrowLeft,
      mdiClose,
    },
  }),
}
</script>
