<template>
  <div>
    <div v-for="(campos, fila) in errores" :key="fila">
      <div class="text-overline font-weight-bold mt-2">
        Fila {{ parseInt(inicio) + parseInt(fila) }}
      </div>
      <v-card outlined>
        <v-simple-table>
          <tbody>
            <template v-for="(errs, campo) in campos">
              <template v-for="error in errs">
                <tr :key="campo + error">
                  <td style="width: 100px">
                    <cargar-chip-campo x-small>
                      {{ campo }}
                    </cargar-chip-campo>
                  </td>
                  <td class="text-caption">
                    {{ error }}
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import CargarChipCampo from '@/admin/views/Nomina/Cargar/CargarChipCampo'
export default {
  components: {
    CargarChipCampo,
  },
  props: {
    errores: {
      type: [Array, Object],
      required: true,
    },
    inicio: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>
