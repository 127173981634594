<template>
  <v-chip
    :color="requerido ? 'success' : color"
    :outlined="outlined"
    :small="!xSmall"
    :x-small="xSmall"
    class="text-lowercase text-caption font-weight-medium"
    label
    dark
  >
    <slot />
  </v-chip>
</template>

<script>
export default {
  props: {
    requerido: Boolean,
    color: {
      type: String,
      default: 'blue-grey darken-2',
    },
    disabled: Boolean,
    outlined: Boolean,
    xSmall: Boolean,
  },
}
</script>
